import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Container from '~components/Container';
import RichText from '~components/RichText';
import CourseCtaButton from '~components/buttons/CourseCtaButton';
import BottomShapeDivider from '~components/ShapeDividers/BottomShapeDivider';
import useCourseEligible from '~hooks/useCourseEligible';
import DateIcon from '~images/icons/date-icon.svg';
import DeviceIcon from '~images/icons/device-icon.svg';
import LevelIcon from '~images/icons/level-icon.svg';
import TimeIcon from '~images/icons/time-icon.svg';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: props => props.background_color || 'inherit',
    position: 'relative'
  },
  contentArea: {
    display: 'flex',
    flexDirection: 'row',
    columnGap: '76px',
    paddingTop: '78px',
    paddingBottom: '78px',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      rowGap: '40px'
    },
    [theme.breakpoints.down('xs')]: {
      paddingTop: '60px',
      paddingBottom: '40px',
      rowGap: '32px'
    }
  },
  textArea: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: '52px',
    [theme.breakpoints.down('xs')]: {
      rowGap: '24px'
    }
  },
  title: {
    color: props => props.text_color,
    lineHeight: '120%',
    fontSize: '32px',
    fontWeight: '600',
    fontFamily: 'Inter',
    margin: '0px',
    [theme.breakpoints.down('xs')]: {
      fontSize: '24px'
    }
  },
  description: {
    maxWidth: '700px',
    '& > *': {
      fontSize: '20px',
      fontFamily: 'Inter',
      lineHeight: '170%',
      marginBlockStart: 0,
      marginBottom: '24px',
      '& > a': {
        fontWeight: '600',
        color: '#000000',
        textDecoration: 'underline'
      },
      color: props => props.text_color
    },
    '& > *:last-child': {
      marginBottom: '0px'
    },
    [theme.breakpoints.down('sm')]: {
      maxWidth: 'unset'
    }
  },
  quote: {
    marginBottom: '110px',
    '& > *': {
      fontSize: '32px',
      fontFamily: 'Inter',
      fontStyle: 'italic',
      fontWeight: '600',
      textAlign: 'center',
      marginBlockStart: 0,
      marginBlockEnd: 0,
      color: props => props.text_color,
      [theme.breakpoints.down('xs')]: {
        fontSize: '24px'
      }
    },
    [theme.breakpoints.down('xs')]: {
      marginBottom: '60px'
    }
  },
  priceDescription: {
    marginTop: '24px',
    '& > *': {
      fontSize: '14px',
      fontFamily: 'Inter',
      lineHeight: '170%',
      marginBlockStart: 0,
      marginBlockEnd: 0,
      color: '#000000',
      '& > a': {
        fontWeight: '600',
        color: '#000000',
        textDecoration: 'underline'
      }
    }
  },
  priceBox: {
    minWidth: '398px',
    width: '398px',
    padding: '60px 55px 40px 55px',
    backgroundColor: '#FFFFFF',
    border: '1px solid #D0D0D0',
    borderRadius: '20px',
    height: 'fit-content',
    [theme.breakpoints.down('sm')]: {
      minWidth: '100%',
      width: '100%',
      order: 1
    },
    [theme.breakpoints.down('xs')]: {
      padding: '60px 30px',
      order: 1
    }
  },
  priceText: {
    margin: '0px',
    fontSize: '36px',
    fontWeight: '800',
    lineHeight: '72%',
    fontFamily: 'Inter',
    textDecoration: props => (props.isCourseEligible ? 'line-through' : 'none'),
    marginBottom: props => (props.isCourseEligible ? '14px' : '38px')
  },
  priceWaveOffText: {
    fontSize: '18px',
    fontWeight: '600',
    lineHeight: '18px',
    fontFamily: 'Inter',
    color: '#6C4BFF',
    margin: '0px 0px 38px'
  },
  priceInfoRow: {
    display: 'flex',
    flexDirection: 'row',
    columnGap: '25px',
    alignItems: 'center',
    marginBottom: '20px'
  },
  priceInfoText: {
    fontSize: '16px',
    fontFamily: 'Inter',
    color: '#4F5465',
    margin: 0
  },
  buttonContainer: {
    marginTop: '50px',
    width: '100%',
    display: 'flex',
    justifyContent: 'center'
  }
}));

const CourseIntroduction = ({
  product_id,
  is_subscriber_eligible,
  is_producer_circle_eligible,
  title,
  date,
  time,
  description,
  price,
  price_description,
  price_wave_off_text,
  audience,
  medium,
  cta_text,
  eligible_cta_text,
  eligible_cta_url,
  cta_text_color,
  cta_background_color,
  quote,
  background_color,
  text_color,
  bottom_shape_divider
}) => {
  const isCourseEligible = useCourseEligible(is_subscriber_eligible, is_producer_circle_eligible);

  const classes = useStyles({
    background_color,
    text_color,
    isCourseEligible
  });

  return (
    <div className={classes.root}>
      <Container center>
        <div className={classes.contentArea}>
          <div className={classes.priceBox}>
            <h3 className={classes.priceText}>{price}</h3>
            {isCourseEligible && price_wave_off_text && (
              <p className={classes.priceWaveOffText}>{price_wave_off_text}</p>
            )}
            <div className={classes.priceInfoRow}>
              <img src={DateIcon} alt="date icon" />
              <p className={classes.priceInfoText}>{date}</p>
            </div>
            <div className={classes.priceInfoRow}>
              <img src={TimeIcon} alt="time icon" />
              <p className={classes.priceInfoText}>{time}</p>
            </div>
            <div className={classes.priceInfoRow}>
              <img src={DeviceIcon} alt="device icon" />
              <p className={classes.priceInfoText}>{medium}</p>
            </div>
            <div className={classes.priceInfoRow}>
              <img src={LevelIcon} alt="level icon" />
              <p className={classes.priceInfoText}>{audience}</p>
            </div>
            {isCourseEligible !== null && (
              <div className={classes.buttonContainer}>
                <CourseCtaButton
                  variant="medium"
                  product_id={product_id}
                  is_subscriber_eligible={is_subscriber_eligible}
                  is_producer_circle_eligible={is_producer_circle_eligible}
                  cta_text={cta_text}
                  eligible_cta_text={eligible_cta_text}
                  eligible_cta_url={eligible_cta_url}
                  cta_text_color={cta_text_color}
                  cta_background_color={cta_background_color}
                />
              </div>
            )}
            {!isCourseEligible && price_description?.html && (
              <RichText
                html={price_description.html}
                verticalSpacing={0}
                externalClassName={classes.priceDescription}
              />
            )}
          </div>
          <div className={classes.textArea}>
            <h2 className={classes.title}>{title}</h2>
            <RichText
              html={description?.html}
              verticalSpacing={0}
              externalClassName={classes.description}
            />
          </div>
        </div>
        <RichText html={quote?.html} verticalSpacing={0} externalClassName={classes.quote} />
      </Container>
      {/* Shape Divider */}
      {bottom_shape_divider?.url && (
        <BottomShapeDivider src={bottom_shape_divider.url} bgColor="transparent" flex />
      )}
    </div>
  );
};

CourseIntroduction.propTypes = {
  product_id: PropTypes.string.isRequired,
  is_subscriber_eligible: PropTypes.string.isRequired,
  is_producer_circle_eligible: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  time: PropTypes.string.isRequired,
  description: PropTypes.shape({ html: PropTypes.string }).isRequired,
  price: PropTypes.string.isRequired,
  price_description: PropTypes.shape({ html: PropTypes.string }).isRequired,
  price_wave_off_text: PropTypes.string,
  audience: PropTypes.string.isRequired,
  medium: PropTypes.string.isRequired,
  cta_text: PropTypes.string.isRequired,
  eligible_cta_text: PropTypes.string,
  eligible_cta_url: PropTypes.string.isRequired,
  cta_text_color: PropTypes.string.isRequired,
  cta_background_color: PropTypes.string.isRequired,
  quote: PropTypes.shape({ html: PropTypes.string }).isRequired,
  background_color: PropTypes.string.isRequired,
  text_color: PropTypes.string.isRequired,
  bottom_shape_divider: PropTypes.shape({
    alt: PropTypes.string,
    url: PropTypes.string
  })
};

CourseIntroduction.defaultProps = {
  eligible_cta_text: '',
  price_wave_off_text: '',
  bottom_shape_divider: null
};

export default CourseIntroduction;
